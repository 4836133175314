<template>
  <div class="restaurant">
    <div class="banner-top">
      <div class="banner-top__bg">
        <img :src="data?.header" alt="" />
      </div>
    </div>
    <div class="category category-orange pc">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <a class="before" href="#">Trang chủ</a>
      <span class="tag-mid">/</span>
      <a class="after" href="#">Tiện Ích</a>
    </div>
    <div class="restaurant-main">
      <div class="title" data-aos="fade-up">
        <h5>{{ data[locale]?.type }}</h5>
        <h1 v-html="data[locale]?.name"></h1>
      </div>
      <div class="content">
        <div class="content__left">
          <div class="item">
            <div class="item__bg"  data-aos="fade-up">
              <img :src="data?.image" alt="" />
            </div>
          </div>
          <div class="item item-pd">
            <div class="item-bg">
              <img src="../assets/img/restaurant/bg_xanh.png" alt="" />
            </div>
            <h3 class="item__title" data-aos="fade-up" v-html="data[locale]?.description">
            </h3>
            <p class="item__des" data-aos="fade-up" v-html="data[locale]?.content">
            </p>
          </div>
        </div>
        <div class="content__right">
          <div class="item">
            <div class="item__avatar"  data-aos="fade-up">
              <img :src="data?.avatar" alt="" />
            </div>
            <div class="item__button"  data-aos="fade-up">
              <a class="text-uppercase" :href="data?.pdf" target="_blank">
                <img src="../assets/img/icons/arrow-right-white.svg" alt="" />
                {{ trans('frontend.button.view menu') }}
              </a>
            </div>
            <div class="item__time-open"  data-aos="fade-up">
              <h5 class="title text-capitalize">{{ trans('frontend.open time') }}</h5>
              <div class="list-box">
                <div class="box" v-for="(item, index) in data.open_times" :key="index">
                  <p class="box__title">{{ item[locale]?.season }}</p>
                  <p class="box__time">{{item[locale]?.business_hours}}</p>
                  <p class="box__day">{{item[locale]?.opening_day}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item__bg"  data-aos="fade-up">
              <img :src="data?.banner" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UtilitesService from "@/services/UtilitesService";
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      data: "",
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.getRestaurant();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    async getRestaurant() {
      await UtilitesService.restaurant(1)
        .then((resp) => {
          this.data = resp.data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
